@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.availability-downtime {
  background: inherit;
}
.dashboard-filter-title {
  margin-left: -50px;
}

.icon-space {
  margin-right: 10px;
}

.pre-formatted {
  white-space: pre-wrap;
}
