@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.active-filter-counter {
  max-width: 500px;
  margin-top: 0.3rem;
  margin-block: 0.1rem;
  .string {
    padding-left: 0.1 rem;
    font-size: var(--font-size-xs);
    font-weight: 300;
    color: var(--color-text-subtle-theme)
  }
}

