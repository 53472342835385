@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-panel--content {
  padding-top: 15px;
  padding-bottom: 30px;
}
::v-deep .col-pu-name {
  width: 25%;
}
::v-deep .col-availability {
  width: 25%;
}
::v-deep .col-downtime-frequency {
  width: 25%;
}
::v-deep .col-downtime-occurrences {
  width: 25%;
}
