@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.menu-date-range-option {
  max-width: 300px;
}
.menu-option {
  max-width: 290px;
}

.background-img {
  z-index: 0;
  background: url(../../assets/img/Group530@2x.png) no-repeat 96% 10%;
  background-size: 20%;

  .row {
    z-index: 1;
  }
}
.date-time-picker {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: white;
  min-height: 480px;
}
.time-selection-dropdown {
  color: black;
}
.time-selector-title {
  background-color: var(--v-primary-base);
}
.time-separator {
  margin-left: -8px;
}
.time-minute-button {
  margin-left: -14px;
}

.responsive-field {
  &.v-text-field {
    @media ($wx-isNotMobile) {
      max-width: 13rem;
    }
  }
}
